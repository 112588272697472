import React from "react";
import { useState, useEffect } from "react";
import { FaShippingFast } from "react-icons/fa";
import { FaDoorOpen } from "react-icons/fa";

import { RiShoppingBagLine } from "react-icons/ri";
import ResetLocation from "../../helpers/ResetLocation";
import { Link, useNavigate } from "react-router-dom";
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import axios from "axios";
import Cookies from 'js-cookie';

import styled from 'styled-components';



// axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
// axios.defaults.headers.put['X-CSRFToken'] = csrfToken;
// axios.defaults.headers.delete['X-CSRFToken'] = csrfToken;

const CustomInput = styled.input`
  border: none;
  background-color: transparent;
  width: 100%;
  font-family: var(--josefin-font);
  font-size: 1rem;
  color: var(--black);
  &:focus {
    outline: none;
    
  }
`;

const CheckoutForm = ({ currentUser, totalPayment, productsQuantity, taxes,checkoutSummary }) => {
  const [formValue, setFormValue] = useState({
    user_name: currentUser.fullname, delivery_address: currentUser.address, phone_number: currentUser.number, choose_Delivery: "", order_items:checkoutSummary
  });
  const [submit, setSubmit] = useState(false);
  const [promoCode, setPromoCode] = useState(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  // const numberRegex = /^[\d\+][\d\(\)\ -]{4,14}\d$/;
  const numberRegex =/^(?:\+)?\d(?:[ (]+)?\d{3}(?:[ )]+)?\d{3}(?:[- ]+)?\d{2}(?:[- ]+)?\d{2}$/gm;
  const [formValueloc, handleValidationloc] = useState();


  const   filterLocationsset= { kladr_id: "2900000200000"}

  const convertData = (data) => {
    const { order_items = [] } = data;
  
    return order_items.map(item => ({
      dish_id: item.id,
      quantity: item.quantity
    }));
  };
  
 const convertedData = convertData(formValue);

  const togglePromocode = () => {
    setPromoCode(!promoCode);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(validateForm(formValue));
    setSubmit(true);
    ResetLocation();
  }
  useEffect(() => {
    if (submit && Object.keys(formError).length === 0) {
      const userNameToSend = formValue.user_name || 'Дорогой клиент (аноним)';
      const delivery_addressToSend = formValue.delivery_address || 'Самовывоз';
     

      const sendOrder = (attempt = 1) => {
        axios({
          headers: {
            'X-CSRFToken': Cookies.get('csrftoken')
          },
          method: 'post',
          url: '/api/backend/order/create/',
          data: {
            order_items: convertData(formValue),
            user_name: userNameToSend,
            delivery_address: delivery_addressToSend,
            phone_number: formValue.phone_number,
            choose_delivery: formValue.choose_Delivery
          },
        })
        .then(response => {
          navigate('/payment');
        })
        .catch(error => {
          if (attempt < 10) {
            const csrfToken = Cookies.get('csrftoken')|| 'CSRF token not received!';
            console.log("csrfToken");
            console.log(csrfToken);
            // console.log(document.cookie);
            console.log(`Попытка ${attempt} не удалась, повторяю...`);
            setTimeout(() => sendOrder(attempt + 1), 2000); // Повторяем через 2 секунды
          } else {
            console.error("Не удалось отправить заказ после нескольких попыток:", error);
            alert("Проверьте интернет соединение!");
          }
        });
      }

      sendOrder(); // Начинаем с первой попытки

      return; 
    }
  }, [submit, formError, navigate, formValue]);

  const handleValidation = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    

    
  }

  

  const validateForm = (value) => {
    let errors = {}
    // if (!value.user_name) {
    //   errors.user_name = "Пожалуйста введите Ваше имя"
    // }

   
    // else if (value.phone_number.length < 11 || !numberRegex.test(value.phone_number)) {
    //   errors.phone_number = "Номер телефона неверен!"
    // }

    if (!checkoutSummary)
        {
          console.log(checkoutSummary);
          setFormValue({ ...formValue, ['order_items']: checkoutSummary });
        }
    if (!value.phone_number) {
      errors.phone_number = "Пожалуйста, введите Ваш номер телефона";
      document.querySelector("#phone_number").focus();

    }
    else if (value.phone_number.length < 11 || !numberRegex.test(value.phone_number)) {
      errors.phone_number = "Пожалуйста, проверьте Ваш номер телефона"
      document.querySelector("#phone_number").focus();
    }


   
    if (formValueloc) {
      console.log(formValueloc.value);
     
      setFormValue({ ...formValue, ['delivery_address']: formValueloc.value });
       }
    if (!value.choose_Delivery) {
      errors.choose_Delivery = "Пожалуйста, выберите вид доставки";
      document.querySelector("#delivery_id").focus();
    }
    if (value.choose_Delivery === "delivery" && totalPayment<10) {
      errors.choose_Delivery = "Доставка доступна только на сумму от 10 ₽";
      document.querySelector("#delivery_id").focus();
    }
    if ((value.choose_Delivery === "delivery" || value.choose_Delivery === "delivery_kv") && !formValueloc) {
      errors.delivery_address = "Пожалуйста, введите адрес доставки"
      // console.log( document.querySelectorAll('div > input:last-child')[0].focus());
      document.querySelectorAll('div > input:last-child')[0].focus();
    }
    if (!value.promoCode && promoCode) {
      errors.promoCode = "Please indicate your promo code"
    }
    if (value.promoCode && value.promoCode.length < 5 && promoCode) {
      errors.promoCode = "Invalid promo code!"
    }

    return errors;
  }

  return (
    
    <section className="checkout-personal-information">



      <form onSubmit={handleSubmit}>


      <h3>Информация о Вас: 
        {/* <span><Link onClick={ResetLocation} to="/profile">Edit profile</Link></span> */}
        </h3>
      <section>
      <p>Как к Вам обращаться:</p>
      <input
          type="text"
          className="menu-search"
          placeholder="Дорогой клиент..."
          onChange={handleValidation}
          name="user_name"
          value={formValue.user_name}
          />
      {/* <span className="fullname-error-cpage">{formError.user_name}</span> */}


         {console.log('formValue', formValue)}
       
         {console.log(convertedData)}     
      
         <p>Мы свяжемся с Вами для уточнения заказа. Обязательно укажите свой телефон:</p>
      <input
      id="phone_number"
          type="text"
          //  pattern="\+7\s?[\(]{0,1}9[0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}"
          className="menu-search"
          placeholder="+7..."
          onChange={handleValidation}
          name="phone_number"
          value={formValue.phone_number}
          autocomplete="on"
          
        />
         <span className="fullname-error-cpage">{formError.phone_number}</span>
     
        <p>{currentUser.fullname}</p>
        <p>{currentUser.email}</p>
        {currentUser.address !== null ?
          <p>{currentUser.address}</p> :
          <p className="checkout-address">You haven't added address yet<span><Link onClick={ResetLocation} to="/profile">Add address</Link></span></p>}
        <span className="fullname-error-cpage">{formError.address}</span>
        {currentUser.number !== null ?
          <p>{currentUser.number}</p> :
          <p className="checkout-number">Please add you contact number<span><Link onClick={ResetLocation} to="/profile">Add number</Link></span></p>}
        <span className="fullname-error-cpage">{formError.number}</span>
      </section>


        <h3>Детали доставки:</h3>
        <label htmlFor="takeaway" className="takeaway-option" name="chooseDelivery">
        <input
            className="takeaway"
            type="radio"
            placeholder="Address"
            value="takeaway"
            name="choose_Delivery"
            onChange={handleValidation}
          />
        <RiShoppingBagLine style={{ width: '3rem' , height: '3rem' }}/>
           Самовывоз: г. Мирный, ул. Гагарина, 1А - 0 ₽
          </label>
        <label htmlFor="delivery" className="delivery-option" name="chooseDelivery">
        <input
            id='delivery_id'
            className="delivery"
            type="radio"
            placeholder="Address"
            value="delivery"
            name="choose_Delivery"
            onChange={handleValidation}
          />
        <FaShippingFast style={{ width: '2rem', height: '2rem' }}/>
        Доставка: г. Мирный, до подъезда - 130 ₽
        </label>
        <label htmlFor="delivery" className="delivery-option" name="chooseDelivery">
              
              <input
            id='delivery_kv_id'
            className="delivery"
            type="radio"
            placeholder="Address"
            value="delivery_kv"
            name="choose_Delivery"
            onChange={handleValidation}
          />
           <FaDoorOpen style={{ width: '2rem', height: '2rem'  }}/>
                      до квартиры - 180 ₽
          
         
        </label>
        <span className="fullname-error-cpage">{formError.choose_Delivery}</span>
        <p>Ваш адрес: улица, дом, квартира</p>
        
    

        <AddressSuggestions token="da9b4d4372daaf1de641003fc15673ecb81dba52" value={formValue.delivery_address} onChange={handleValidationloc}  border= "1px solid transparent"
              // className="menu-search"
              // filterFromBound="Архангельская обл, г Мирный, ул "
             
              filterLocations={filterLocationsset}
              // name="delivery_address"
              //  defaultQuery="Архангельская обл, г Мирный, ул"
               containerClassName= "menu-search2"
              //  hintClassName= "menu-search2"
              httpCache="True"
              
              // filterLocations= filterLocationsset
              uid="delivery_address"
              selectOnBlur="true"
              customInput={CustomInput}
        />

      {/* <input
          id="address"
          type="text"
          className="menu-search"
          placeholder="ул. ... "
          name="delivery_address"
          onChange={handleValidation}
          value={formValue.delivery_address}
          
        /> */}

        

        <span className="fullname-error-cpage">{formError.delivery_address}</span>


        {/* <section className="promo-code">

          {promoCode === false ? <p onClick={togglePromocode}>I have a promo code!</p> : (
            <React.Fragment>
              <p onClick={togglePromocode}>No promo code</p>
              <input
                name="promoCode"
                className=" pop-font"
                type="text"
                placeholder="Enter the 5-digit code"
                onChange={handleValidation}
                value={formValue.promoCode}
              />
            </React.Fragment>
          )}
          <span className="fullname-error-cpage">{formError.promoCode}</span>
        </section> */}
        <article className="checkout-carttotals">
          {productsQuantity === 0 ? null : (
            <section className="cart-totals">
              {/* <section className="totals-content">
                <h4 className="cart-totals-sum">Tax 10%:</h4>
                <p>$ {taxes}</p>
              </section> */}
              <section className="totals-content">
                <h4 className="cart-totals-sum">Количество:</h4>
                <p> {productsQuantity}</p>
              </section>
              <section className="totals-content" >
                <h4 className="cart-totals-sum">Сумма:</h4>
                <p>₽ {parseFloat(totalPayment) + (formValue.choose_Delivery === "delivery_kv" ? 180 : (formValue.choose_Delivery === "delivery" ? 130 : 0))}</p>
                </section>
            </section>

          )}
        </article>
        <button type="submit" className="active-button-style">
          Подтвердить заказ
        </button>
      </form>
    </section>
  );
}
 

export default CheckoutForm;
